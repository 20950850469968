import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import './index.css';
import prod1 from '../../assets/images/products/product-1.jpg'
import prod2 from '../../assets/images/products/product-2.jpg'
import prod3 from '../../assets/images/products/product-3.jpg'
import prod4 from '../../assets/images/products/product-4.jpg'

const columns: GridColDef[] = [
  { field: 'avatar', headerName: '', width: 40,
    renderCell: (params) => (
      <img src={params.row.avatar} alt='' />
    ),
  },
  { field: 'product', headerName: 'Product', width: 200 },
  { field: 'tags', headerName: 'Tags', width: 200 },
  { field: 'asin', headerName: 'ASIN', width: 120 },
  { field: 'price', headerName: 'Price', type: 'number', width: 50 },
  { field: 'target', headerName: 'Target AcoS', type: 'number', width: 50 },
  { field: 'bsr', headerName: 'BSR', width: 120 },
  { field: 'rating', headerName: 'Rating', type: 'number', width: 60 },
  { field: 'date', headerName: 'Publication Date', width: 120 },
  { field: 'royalties', headerName: 'All Time Royalties', type: 'number', width: 120 },
  { field: 'sold', headerName: 'All Time Unit Sold', type: 'number', width: 120 },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params: GridValueGetterParams) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

const rows = [
  { id: 1, avatar: prod1, product: 'The Power of Pivotin', productSubText: 'Monica Ortega', tags: 'Fiction, Drama, Romance, Comedy', asin: '123DFF450', price: '$2', target: '2%', bsr: 'US #3,456 | UK #2,445', rating: 1346, date: 12/2/2023, royalties: '$1346', sold: 23849 },
  { id: 2, avatar: prod2, product: 'The Power of Pivotin', productSubText: 'Monica Ortega', tags: 'Fiction, Drama, Romance, Comedy', asin: '123DFF450', price: '$2', target: '2%', bsr: 'US #3,456 | UK #2,445', rating: 1346, date: 12/2/2023, royalties: '$1346', sold: 23849 },
  { id: 3, avatar: prod3, product: 'The Power of Pivotin', productSubText: 'Monica Ortega', tags: 'Fiction, Drama, Romance, Comedy', asin: '123DFF450', price: '$2', target: '2%', bsr: 'US #3,456 | UK #2,445', rating: 1346, date: 12/2/2023, royalties: '$1346', sold: 23849 },
  { id: 4, avatar: prod4, product: 'The Power of Pivotin', productSubText: 'Monica Ortega', tags: 'Fiction, Drama, Romance, Comedy', asin: '123DFF450', price: '$2', target: '2%', bsr: 'US #3,456 | UK #2,445', rating: 1346, date: 12/2/2023, royalties: '$1346', sold: 23849 },
  { id: 5, avatar: prod1, product: 'The Power of Pivotin', productSubText: 'Monica Ortega', tags: 'Fiction, Drama, Romance, Comedy', asin: '123DFF450', price: '$2', target: '2%', bsr: 'US #3,456 | UK #2,445', rating: 1346, date: 12/2/2023, royalties: '$1346', sold: 23849 },
];

export default function CusDataTable() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}
