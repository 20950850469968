import React from 'react';
import './index.css';
import { Link } from 'react-router-dom'

import Logo from "../../assets/brand/logo-icon.svg"
import menuItems from './menu.json'
const Sidebar = () => {
  return (
      <div className='sidebar'>
        <div className='header-wrapper'>
          <div className='brand-logo'>
            <img src={Logo} alt='logo' />
          </div>
        </div>
        <div>
          <nav>
            <ul className="menus">
              {menuItems.map((menu, index) => {
                return (
                  <li className="menu-items" key={index}>
                    <Link to={menu.to}><span className={menu.icon}></span></Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
  );
};

export default Sidebar;