import React from 'react';
import { GoogleLogin } from 'react-google-login';
const SignInWithGoogle = () => {
  const responseGoogle = (response) => {
    console.log(response);
    // Handle the Google Sign-In response here
  };

  return (
     <GoogleLogin
  clientId="YOUR_CLIENT_ID"
  buttonText="Continue with Google "
  onSuccess={responseGoogle}
  onFailure={responseGoogle}
  cookiePolicy={'single_host_origin'}
/>
  );
};
export default SignInWithGoogle;