import React from 'react';
import { GoogleLogin } from 'react-google-login';

const SignupWithGoogle = () => {
  const responseGoogle = (response) => {
    // Handle the Google login response here
    console.log(response);
    // Send the response to your server for signup
    // You may need to make an API call to your server with the response details
  };

  return (
    <div>
      {/* Other signup form fields */}
      <GoogleLogin
  clientId="YOUR_CLIENT_ID"
  buttonText="Continue with Google"
  onSuccess={responseGoogle}
  onFailure={responseGoogle}
  cookiePolicy={'single_host_origin'}
/>
    </div>
  );
};

export default SignupWithGoogle;
