import React from 'react';
import Sidebar from '../../shared/Sidebar';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import {Container} from 'react-bootstrap';
import './index.css';

interface Props {
  children: React.ReactNode
}

const DashboardLayout: React.FunctionComponent<Props> = (props:Props) => {
    return (
      <Container className='admin-bg p-0' fluid>
        <div className='sidebar-main-container'>
          <Sidebar />
        </div>
        <div className='maincont'>
          <Header />
          <main>{props.children}</main>
          <Footer />
        </div>
      </Container>
    );
}
export default DashboardLayout;