import { Routes, Route } from 'react-router-dom';
// import PrivateRoute from './routes/privateRoutes';

import SignIn from './views/Signin'
import SignUp from './views/Signup';
import Forgotpassword from './views/Forgotpassword';
import Page404 from './views/Page404';

import Dashboard from './views/Dashboard';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

export const App = () => {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password" element={<Forgotpassword />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {/* Private Route */}
      {/* <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      /> */}
      <Route path="*" element={<Page404 />} />
    </Routes>
    </div>
  );
}

export default App;
