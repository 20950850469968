import React, { Component } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

import CusDataTable from '../../components/CusDataTable';

export default class Dashboard extends Component {
  render() {
    return (
      <DashboardLayout>
        <div>
          <h4>DataTable Table</h4>
          <CusDataTable />
        </div>
      </DashboardLayout>
    )
  }
}