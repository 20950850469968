import React from 'react';

class Footer extends React.Component
{
  render()
  {
    return (
        <div className="jumbotron text-center">
        <p>Footer</p>
      </div>
    )
  }
}
export default Footer;
