import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link } from "react-router-dom";
import SignInWithFacebook from "../../components/sign/SignInWithFacebook";
import SignInWithGoogle from "../../components/sign/GoogleSignIn";
interface FormState {
  email: string;
  password: string;
  showPassword: boolean;
  errors: {
    email: string;
    password: string;
  };
}

class SignIn extends Component<{}, FormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      errors: {
        email: "",
        password: "",
      },
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const { email, password } = this.state;

    // Perform validation
    const errors: FormState["errors"] = {
      email: "",
      password: "",
    };

     if (!email) {
      errors.email = "Email is required";
    } else if (!this.validateEmail(email)) {
      errors.email = "Invalid email address";
    }

    if (!password) {
      errors.password = "Password is required";
    }

    if (Object.values(errors).some((error) => error !== "")) {
      this.setState({ errors });
    } else {
      // Submit the form or perform other actions
      console.log("Form submitted");
    }
  };
  validateEmail = (email: string) => {
    // Email validation logic (you can use a library or a custom regex pattern)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };
  render() {
    const { email, password, errors,showPassword  } = this.state;
    const isFormValid = email !== '' && password !== '';

    return (
      <DefaultLayout>
          <div className="auth-login-icon google-login-icon">
            {/*<img src={GoogleIcon} alt="google icon" />
            <span>Continue with Google</span>*/}
            <div className="googleicon"><SignInWithGoogle/></div>
          </div>
          <div className="auth-login-icon facebook-login-icon mb-4">
           {/*<img src={FacebookIcon} alt="facebook icon" />
            <span>Continue with Facebook</span>*/} <SignInWithFacebook/>
          </div>
        <form onSubmit={this.handleSubmit}>
          <div className="separator mb-4">Or</div>
          <div className="mb-3">
            <input
              type="text"
              name="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={this.handleInputChange}
              
            />
            {errors.email && <span className="validation-error">{errors.email}</span>}
          </div>
          <div className="mb-0 password-cont" >
            <input
            name='password'
            type={showPassword ? 'text' : 'password'}               
            className="form-control"
              placeholder="Password"
              value={password}
              onChange={this.handleInputChange}
            />
             <span className="show-password"  onClick={this.togglePasswordVisibility}>
          {showPassword ? (
            <i className="fas fa-eye-slash"></i>
          ) : (
            <i className="fas fa-eye"></i>
          )}
        </span>
             {errors.password && <span className="validation-error">{errors.password}</span>}
          </div>
          <div className="mb-1">
            <p className="forgot-password text-right"  >
              <Link to={"/forgot-password"}>Forgot Password?</Link>
            </p>
          </div>
          <div className="mb-4">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input login-checkbox"
                id="customCheck1"
              />
              <label
                className="custom-control-label ml-3"
                htmlFor="customCheck1"
              >
                Remember me
              </label>
            </div>
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary" disabled={!isFormValid}>
              Log in
            </button>
          </div>
          <div className="new-create-account">
            <p className="text-center">
              No account?{" "}
              <Link to={"/sign-up"}>Sign Up</Link>
            </p>
          </div>
        </form>
      </DefaultLayout>
    );
  }
}
export default SignIn;
